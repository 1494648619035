/* Common styles */
html, body {
  height: 100%
}
#root {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.full_content{
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}
footer {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.breadcrumbs {
  margin-left: 10px;
}
.breadcrumbs a {
  outline: none;
  text-decoration: none;
  color: #000;
}
.breadcrumbs a:hover {
  color: #4682B4;
  border-bottom: 1px solid #4682B4;
}
.text-right {
  text-align: right;
}
.app .container {
  margin: 10px;
  padding: 10px;
  border: 2px solid #000;
  border-radius: 5px;
}
.app .container .flex {
  display: -webkit-flex;
  display: flex;
}
.app .baseline-data-content {
  margin-top: 10px;
}
.app .switch-block {
  width: 25%;
}
.app .left-sidebar {
  width: 20%;
}
.app .left-sidebar.left-sidebar-baseline {
  border: 2px solid #000;
  border-radius: 5px;
  width: 20%;
}
.app .left-sidebar.left-sidebar-baseline .tables-list {
  margin: 5px 15px;
  border: 2px solid #000;
  border-radius: 5px;
}
.app .left-sidebar.left-sidebar-baseline .tables-list .table-item {
  padding: 5px;
}
.app .left-sidebar.left-sidebar-baseline .tables-list .table-item:hover,
.app .left-sidebar.left-sidebar-baseline .tables-list .table-item.active{
  background: rgba(128,128,128, 0.4);
  cursor: pointer;
}
.app .visibility-types {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
.app .visibility-types .type {
  padding: 10px;
  border: 2px solid #000;
  border-radius: 5px;
}
.app .visibility-types .type.active {
  background-color: #87CEFA;
}
.app .left-sidebar .metrics-list {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 10px 0;
}
.app .left-sidebar .metrics-list .metrics-list-item:first-child {
  margin-top: 5px;
}
.app .left-sidebar .metrics-list .metrics-list-item {
  border: 2px solid #000;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 10px 25px;
  text-align: center;
}
.button-run {
  width: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  outline: none;
  padding: 5px 0;
  background-color: #90EE90;
  cursor: pointer;
}
.button-cancel {
  width: 100%;
  border: 2px solid #000;
  border-radius: 5px;
  outline: none;
  padding: 5px 0;
  background-color: #FFF;
  cursor: pointer;
}
.app .width20 {
  width: 20%;
}
.app .mt10 {
  margin-top: 10px;
}
.app .content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 80%;
}
.app .baseline-data-content .content {
  padding: 0 10px;
}
.app .content .map {
  border: 2px solid #000;
  border-radius: 5px;
  margin-left: 10px;
  -webkit-flex: 1;
          flex: 1;
}
.app .content .chart {
  border: 2px solid #000;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  -webkit-flex: 1;
          flex: 1;
}
.chart-container {
  height: 100%;
  width: 100%;
}
.app .output-filters {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-left: 10px;
}
.app .output-filters .output-filter-item {
  -webkit-flex: 1;
          flex: 1;
  margin-right: 15px;
}
.app .output-filters .output-filter-item:last-child {
  margin-right: 0;
}
.configure-scenario-modal .modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #000;
}
.configure-scenario-modal .modal-header .modal-header-title {
  width: 100%;
  text-align: center;
}
.configure-scenario-modal .modal-subtitle {
  font-weight: bold;
  margin: 15px 0;
}
.configure-scenario-modal .config-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 10px;
}
.configure-scenario-modal .config-item:last-child {
  margin-bottom: 0;
}
.configure-scenario-modal .config-item div:first-child {
  margin-right: 10px;
}
.configure-scenario-modal .configure-actions {
  display: -webkit-flex;
  display: flex;
  margin-top: 15px;
}
.configure-scenario-modal .configure-actions button:first-child {
  margin-right: 10px;
}
.scenario-map-item-title:first-child {
  margin-top: 0;
}
.scenario-map-item-title {
  margin-left: 10px;
  margin-top: 10px;
}
/*remove*/
.temp-table {
  border-spacing: 0;
  width: 100%;
}
.temp-table td {
  border: 1px solid #000;
  padding: 5px;
}
button {cursor: pointer}
.inventoryContainer {
  height: 500px;
}
.gridjs-th:hover::after {
  content: attr(data-column-id);
  position: absolute; /* Абсолютное позиционирование */
  left: 5%; top: 105%; /* Положение подсказки */
  z-index: 9999999; /* Отображаем подсказку поверх других элементов */
  background: rgba(255,255,230,0.9); /* Полупрозрачный цвет фона */
  font-family: Arial, sans-serif; /* Гарнитура шрифта */
  font-size: 11px; /* Размер текста подсказки */
  padding: 5px 10px; /* Поля */
  border: 1px solid #333; /* Параметры рамки */
}
.leaflet-tooltip {
  max-height: 55px;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.leaflet-control-zoom.leaflet-bar {
  border: none;
}
body {
    margin: 0;
    font-family: 'Founders-Regular'
  }
@font-face {
    font-family: 'Danzza-Light';
    src: local('Danzza-Light'), url(./fonts/Danzza_Light.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Danzza-Bold';
    src: local('Danzza-Bold'), url(./fonts/Danzza_Bold.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Danzza-Regular';
    src: local('Danzza-Regular'), url(./fonts/Danzza_Regular.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Danzza';
    src: local('Danzza'), url(./fonts/Danzza_Medium.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Founders-Regular';
    src: local('Founders-Regular'), url(./fonts/founders-grotesk-web-regular.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Founders-Bold';
    src: local('Founders-Bold'), url(./fonts/founders-grotesk-web-semibold.woff) format('woff');
    font-weight: normal;
  }
@font-face {
    font-family: 'Founders-Mono-Regular';
    src: local('Founders-Mono-Regular'), url(./fonts/founders-grotesk-mono-web-regular.woff) format('woff');
    font-weight: normal;
  }